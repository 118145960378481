import dayjs from 'dayjs';

function dateRange(unit: dayjs.OpUnitType): [number, number] {
  return [
    dayjs().startOf(unit).valueOf(),
    dayjs().endOf(unit).valueOf()
  ];
}

function dateFormat(date: Date, format: string): string {
  return dayjs(date).format(format);
}

export { dayjs, dateRange, dateFormat };
