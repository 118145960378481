import { createIconifyIcon } from '../create-icon.ts';
import './load';

const SvgStarOutlineIcon = createIconifyIcon('svg:star-outline');

const SvgReturnIcon = createIconifyIcon('svg:return');

const SvgShoppingCartIcon = createIconifyIcon('svg:shopping-cart');

const SvgQrScanIcon = createIconifyIcon('svg:qr-scan');

const SvgAiTryonIcon = createIconifyIcon('svg:ai-tryon');

const SvgDesignIcon = createIconifyIcon('svg:design');

const SvgBadgeSDIcon = createIconifyIcon('svg:badge-sd');

const SvgBadgeHDIcon = createIconifyIcon('svg:badge-hd');

const SvgCameraOutlineIcon = createIconifyIcon('svg:camera-outline');

const SvgArchiveExportIcon = createIconifyIcon('svg:archive-export');

const SvgAddressBookIcon = createIconifyIcon('svg:address-book');

const SvgRemoveCircleOutlineIcon = createIconifyIcon('svg:remove-circle-outline');

const SvgSelectMultipleIcon = createIconifyIcon('svg:select-multiple');

const SvgEllipsisHIcon = createIconifyIcon('svg:elipsis-h');

const SvgOpenInNewIcon = createIconifyIcon('svg:open-in-new');

const SvgColorPickerFillIcon = createIconifyIcon('svg:color-picker-fill');

const SvgPlusCircleOutlined = createIconifyIcon('svg:plus-circle-outlined');

const SvgPlusOutlined = createIconifyIcon('svg:plus-outlined');

const SvgSquareTransferOutline = createIconifyIcon('svg:square-transfer-outline');

const SvgColorPalette =createIconifyIcon('svg:color-palette');

export {
  SvgStarOutlineIcon,
  SvgReturnIcon,
  SvgShoppingCartIcon,
  SvgQrScanIcon,
  SvgAiTryonIcon,
  SvgDesignIcon,
  SvgBadgeSDIcon,
  SvgBadgeHDIcon,
  SvgCameraOutlineIcon,
  SvgArchiveExportIcon,
  SvgAddressBookIcon,
  SvgRemoveCircleOutlineIcon,
  SvgSelectMultipleIcon,
  SvgEllipsisHIcon,
  SvgOpenInNewIcon,
  SvgColorPickerFillIcon,
  SvgPlusCircleOutlined,
  SvgPlusOutlined,
  SvgSquareTransferOutline,
  SvgColorPalette
};

