function imageUrlToDataUri(url: string): Promise<string> {
  return new Promise((resolve, _reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function() {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        return resolve('');
      }

      ctx.drawImage(img, 0, 0);
      const dataUri = canvas.toDataURL('image/png');
      resolve(dataUri);
    };
    img.onerror = function() {
      resolve('');
    };

    img.src = url;
  });
}

function loadImageData(url: string): Promise<ImageData> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function() {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        throw new Error('get context occurred error');
      }

      ctx.drawImage(img, 0, 0);
      const imageData = ctx.getImageData(0, 0, img.width, img.height);
      resolve(imageData);
    };
    img.onerror = function(err: any) {
      reject(err);
    };
    img.src = url;
  });
}

function loadImageFromUrl(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function() {
      resolve(img);
    };
    img.onerror = function() {
      reject();
    };

    imageUrlToDataUri(url).then(dataUri => {
      img.src = dataUri;
    });
  });
}

function blobToBase64(blob: Blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

function getImageSize(url: string): Promise<{ width: number, height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function() {
      resolve({ width: img.width, height: img.height });
    };

    img.onerror = function() {
      reject(new Error('load image failed'));
    };

    img.src = url;
  });
}

function base64ToFile(base64: string, filename: string): File {
  const arr = base64.split(',');
  if (arr.length !== 2) {
    throw new Error('Invalid base64 string format');
  }

  const mime = arr[0]?.match(/:(.*?);/)?.[1] ?? 'application/octet-stream';
  if (!arr[1]) {
    throw new Error('Base64 data is missing');
  }
  const bstr = atob(arr[1]);
  const n = bstr.length;
  const u8arr = new Uint8Array(n);

  for (let i = 0; i < n; i++) {
    u8arr[i] = bstr.charCodeAt(i);
  }

  return new File([u8arr], filename, { type: mime });
}

export { loadImageData, imageUrlToDataUri, loadImageFromUrl, blobToBase64, getImageSize, base64ToFile };
